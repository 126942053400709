import React, { FC, useEffect, lazy, Suspense } from "react";
import { Link, Redirect, Route, Switch, useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

import { SystemIdentifierEnum } from "modules/gestaoVendas/domain/enum/GetSegmentosEnum";
import { MenuProvider } from "components/layout/presentation/menu/context/MenuContext";
import { PlansProvider } from "modules/plans/presentation/hooks/PlansContext";
import { AuthProvider } from "modules/auth/presentation/context/AuthContext";
import { BreadcumbsProvider } from "components/breadcumbs/BreadcumbsContext";
import { UserProvider } from "modules/user/domains/presentation/contexts/UserContext";
import { TipsProvider } from "components/breadcumbs/TipsContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import Utils from "services/utils/Utils";

const Collaborators = lazy(() => import('pages/private/agendamento/colaboradores/lista'));
const EditProduct = lazy(() => import('pages/private/produtos/editar'));
const AdicionarProduto = lazy(() => import('pages/private/produtos/adicionar'));
const Crm = lazy(() => import('pages/private/crm'));
const Report = lazy(() => import('pages/private/crm/_report'));
const CrmCampaignEdit = lazy(() => import('pages/private/crm/campaign/edit'));
const Automations = lazy(() => import('pages/private/automation'));
const FidelityPageContainer = lazy(() => import('modules/fidelity/presentation/FidelityPageContainer'));
const SurveyLandingPage = lazy(() => import('modules/survey/presentation/pages/landing/SurveyLandingPage'));
const TrackDetail = lazy(() => import('modules/survey/presentation/pages/trackDetail/TrackDetail'));
const TrackPage = lazy(() => import('modules/survey/presentation/pages/track/Track'));
const ERPLandingPage = lazy(() => import('modules/erp/presentation/pages/landing/ERPLandingPage'));
const OmieDetail = lazy(() => import('modules/erp/presentation/pages/omieDetail/OmieDetail'));
const SankhyaDetail = lazy(() => import('modules/erp/presentation/pages/sankhyaDetail/SankhyaDetail'));
const EverestDetail = lazy(() => import('modules/erp/presentation/pages/everestDetail/EverestDetail'));
const Plans = lazy(() => import('modules/plans/presentation/pages/plans/Plans'));
const PurchasePlan = lazy(() => import('modules/plans/presentation/pages/purchasePlan/PurchasePlan'));
const PlanPaymentHistory = lazy(() => import('modules/plans/presentation/pages/planPaymentHistory/PlanPaymentHistory'));
const PurchaseBi = lazy(() => import('modules/bi/presentation/pages/purchaseBi/PurchaseBi'));
const PurchasePush = lazy(() => import('modules/addons/presentation/pages/purchasePush/PurchasePush'));
const PurchaseEmail = lazy(() => import('modules/addons/presentation/pages/purchaseEmail/PurchaseEmail'));
const AutomationPage = lazy(() => import('modules/automation/presentation/AutomationPage'));
const ConfigBI = lazy(() => import('modules/bi/presentation/pages/config/ConfigBI'));
const Onboarding = lazy(() => import('modules/onboarding/presentation/pages/Onboarding'));
const BIPageContainer = lazy(() => import('modules/bi/presentation/pages/BiPageContainer'));
const ConfigPlans = lazy(() => import('modules/plans/presentation/pages/config/ConfigPlans'));
const AddProduct = lazy(() => import('pages/private/catalog/product/addOrUpdate'));
const ConfigOnlinePayment = lazy(() => import('modules/onlinePayment/presentation/pages/config/ConfigOnlinePayment'));
const PublicGuestList = lazy(() => import('public/modules/guestList/PublicGuestList'));
const PaymentPage = lazy(() => import('modules/saas/presentation/pages/Payment/PaymentPage'));
const TrialPage = lazy(() => import('modules/saas/presentation/pages/Trial/TrialPage'));
const FeesPage = lazy(() => import('modules/saas/presentation/pages/Fees/FeesPage'));
const SaasTrackPage = lazy(() => import('modules/saas/presentation/pages/Track/TrackPage'));
const SuccessPage = lazy(() => import('modules/saas/presentation/pages/Success/SuccessPage'));
const ChangePaymentMethod = lazy(() => import('modules/saasPayment/presentation/ChangePaymentMethod'));
const BasicSuccessPage = lazy(() => import('modules/saas/presentation/pages/BasicSuccess/BasicSuccessPage'));
const TrackingConfig = lazy(() => import('modules/saas/presentation/pages/trackConfig/TrackConfig'));
const Terminal = lazy(() => import('pages/private/terminal'));
const SaasPayment = lazy(() => import('pages/private/saasPayment'));
const SaasPaymentSuccess = lazy(() => import('pages/private/saasPaymentSuccess'));
const Posts = lazy(() => import('modules/posts/presentation/pages/Posts/Posts'));
const SaleNow = lazy(() => import('pages/private/terminal/venderAgora'));
const SaasHome = lazy(() => import('pages/private/saasHome'));
const InvoicePage = lazy(() => import('modules/invoice/presentation/InvoicePage'));
const InvoiceForm = lazy(() => import('modules/invoice/presentation/InvoiceForm/InvoiceForm'));
const SaasSaleWays = lazy(() => import('pages/private/saasSaleWays'));
const PublicInvoicePage = lazy(() => import('public/modules/invoice/PublicInvoicePage'));
const TaxForm = lazy(() => import('modules/invoice/presentation/TaxForm/TaxForm'));
const SaasChangePlan = lazy(() => import('pages/private/saasChangePlan'));
const ErpRoutes = lazy(() => import('modules/meepErp/presentation/ErpRoutes'));
const Orders = lazy(() => import('modules/order/presentation/pages/Orders/Orders'));
const SimpleSuccessPage = lazy(() => import('modules/saas/presentation/pages/SimpleSuccess/SimpleSuccessPage'));
const CieloPosTable = lazy(() => import('modules/saas/presentation/pages/CieloPosTable/CieloPosTable'));
const AddColaborator = lazy(() => import('pages/private/agendamento/colaboradores/adicionar'));
const EditColaborator = lazy(() => import('pages/private/agendamento/colaboradores/editar'));
const PlaceSchedule = lazy(() => import('pages/private/agendamento/lugares'));
const Login = lazy(() => import('pages/login'));
const Services = lazy(() => import('pages/private/agendamento/servicos'));
const AddServicesSchedule = lazy(() => import('pages/private/agendamento/servicos/adicionar'));
const AddPlaceSchedule = lazy(() => import('pages/private/agendamento/lugares/adicionar'));
const Events = lazy(() => import('pages/private/eventos'));
const AddEvents = lazy(() => import('pages/private/eventos/adicionar'));
const AddGuestList = lazy(() => import('pages/private/eventos/listas/adicionar'));
const GuestList = lazy(() => import('pages/private/eventos/listas/convidados'));
const AddGuest = lazy(() => import('pages/private/eventos/listas/convidados/adicionar'));
const AddReserveSchedule = lazy(() => import('pages/private/agendamento/reserva/adicionar'));
const ReserveList = lazy(() => import('pages/private/agendamento/listas/reserva'));
const EventPeopleList = lazy(() => import('pages/private/eventos/convidados'));
const EditEvents = lazy(() => import('pages/private/eventos/editar'));
const EventGuestLists = lazy(() => import('pages/private/eventos/listas'));
const EditServicesSchedule = lazy(() => import('pages/private/agendamento/servicos/editar'));
const FormPlaceSchedule = lazy(() => import('pages/private/agendamento/lugares/editar'));
const Discounts = lazy(() => import('pages/private/eventos/descontos'));
const AddDiscountsPage = lazy(() => import('pages/private/eventos/descontos/adicionar'));
const Dashboard = lazy(() => import('pages/private/dashboard'));
const Customers = lazy(() => import('pages/private/customers'));
const ServiceCategories = lazy(() => import('pages/private/agendamento/servicos/categorias'));
const CustomField = lazy(() => import('pages/private/agendamento/lugares/customizarCampos'));
const Manage = lazy(() => import('pages/private/eventos/gerenciar'));
const CloseEvent = lazy(() => import('pages/private/eventos/fechamento'));
const SmartPrinter = lazy(() => import('pages/private/config/impressoraInteligente'));
const Local = lazy(() => import('pages/private/local'));
const Painel = lazy(() => import('pages/private/Painel'));
const Products = lazy(() => import('pages/private/produtos'));
const Cadastros = lazy(() => import('pages/private/cadastros'));
const Agendamento = lazy(() => import('pages/private/agendamento'));
const Cartoes = lazy(() => import('pages/private/cartoes'));
const Relatorio = lazy(() => import('pages/private/relatorio'));
const Signup = lazy(() => import('modules/auth/presentation/page/signup/Signup'));
const ConfigCampanha = lazy(() => import('pages/private/crm/configuracoesCampanha'));
const HouseFlow = lazy(() => import('pages/private/houseFlow'));
const DiscountCoupons = lazy(() => import('pages/private/config/cuponsDesconto'));
const Integracao = lazy(() => import('pages/private/integracoes'));
const Faturas = lazy(() => import('pages/private/faturas'));
const Transacoes = lazy(() => import('pages/private/transacoes'));
const PosPago = lazy(() => import('pages/private/posPago'));
const Estoque = lazy(() => import('pages/private/estoque'));
const Servicos = lazy(() => import('pages/private/servicos'));
const Cashless = lazy(() => import('pages/private/cashlees'));
const Doacoes = lazy(() => import('pages/private/doacoes'));
const Configuracao = lazy(() => import('pages/private/config'));
const MeepFood = lazy(() => import('pages/private/meepFood'));
const Admin = lazy(() => import('pages/private/admin'));
const Colaboradores = lazy(() => import('pages/private/colaboradores'));
const CustomerCategories = lazy(() => import('modules/customerCategories/presentation/pages/CustomerCategories'));
const Cargos = lazy(() => import('pages/private/cargos'));
const Monitoramento = lazy(() => import('pages/private/monitoramento'));
const Device = lazy(() => import('pages/private/device'));
const MeepTickets = lazy(() => import('pages/private/meepTickets'));
const Novidades = lazy(() => import('pages/private/novidades'));
const Tutoriais = lazy(() => import('pages/private/tutoriais'));
const AdminNovidades = lazy(() => import('pages/private/adminNovidades'));
const Perfil = lazy(() => import('pages/private/perfil'));
const PerfilEventos = lazy(() => import('pages/private/perfil/eventos'));
const GestaoVendas = lazy(() => import('pages/private/gestoaVendas'));
const Catalog = lazy(() => import('pages/private/catalog'));
const LimitarAcesso = lazy(() => import('pages/private/limitarAcesso'));
const ContaDigital = lazy(() => import('pages/private/contaDigital'));
const Extrato = lazy(() => import('pages/private/contaDigital/extrato'));
const LoginPage = lazy(() => import('modules/saas/presentation/pages/Login/LoginPage'));
const ForgetPasswordPage = lazy(() => import('modules/saas/presentation/pages/ForgetPassword/ForgetPasswordPage'));
const ChangePasswordPage = lazy(() => import('modules/saas/presentation/pages/ChangePassword/ChangePasswordPage'));
const CodePage = lazy(() => import('modules/saas/presentation/pages/Code/CodePage'));
const ProfilePage = lazy(() => import('modules/saas/presentation/pages/Profile/ProfilePage'));
const LocalPage = lazy(() => import('modules/saas/presentation/pages/Local/LocalPage'));
const AddressPage = lazy(() => import('modules/saas/presentation/pages/Address/AddressPage'));
const AntecipateReceived = lazy(() => import('pages/private/contaDigital/antecipateReceived'));
const Antecipate = lazy(() => import('pages/private/contaDigital/antecipate'));
const Permutation = lazy(() => import('pages/private/permutation'));
const Layout = lazy(() => import('components/layout/presentation/Layout'));
const MainDashboard = lazy(() => import('pages/private/mainDashboard'));
const DigitalMenuConfigPage = lazy(() => import('modules/digitalMenu/presentation/pages/DigitalMenuConfigPage'));
const SaasRegister = lazy(() => import('pages/saasRegister'));
const External = lazy(() => import('pages/private/external/External'));
const CadastrarTutorial = lazy(() => import('pages/private/cadastrarTutorial'));
const AccessDeniedPage = lazy(() => import('pages/accessDenied/AccessDeniedPage'));
const SimpleLoginPage = lazy(() => import('modules/saas/presentation/pages/simpleLogin/SimpleLoginPage'));
const ToolsPage = lazy(() => import('modules/tools/presentation/pages/tools/ToolsPage'));
const IfoodCalculatorPage = lazy(() => import('modules/tools/presentation/pages/ifoodCalculator/IfoodCalculatorPage'));
const CoilCalculatorPage = lazy(() => import('modules/tools/presentation/pages/coilCalculator/CoilCalculatorPage'));
const PaymentLink = lazy(() => import('pages/private/paymentLink'));
const Donations = lazy(() => import('pages/private/donations'));
const CieloSystemPage = lazy(() => import('modules/saas/presentation/pages/CieloSystem/CieloSystemPage'));
const ForgotPassword = lazy(() => import('pages/forgotPassword'));
const PlanLimitationWarning = lazy(() => import('modules/saas/presentation/pages/PlanLimitationWarning/PlanLimitationWarning'));
const SimpleForgetPassword = lazy(() => import('modules/saas/presentation/pages/simpleForgetPassword/SimpleForgetPassword'));
const MeepFee = lazy(() => import('pages/private/meepFee'));
const DigitalMenu = lazy(() => import('pages/private/digitalMenu'));
const LinkCards = lazy(() => import('pages/private/linkCards'));
const CieloSellerPage = lazy(() => import('modules/saas/presentation/pages/CieloSeller/CieloSellerPage'));

export interface IRoutesProps {
  //propertys
}
const Routes: FC<IRoutesProps> = () => {
  const Home = () => {
    const { replace } = useHistory()
    const { currentLocal } = useLocal()

    useEffect(() => {
      if (localStorage.getItem("@token") === null) {
        replace("/login")
      }
    }, [replace])

    // TODO - Remover quando dashboard geral ficar disponível para todos
    const getPath = () => {
      if (Utils.localsWithGeneralDashboardAccess.includes(currentLocal?.id ?? "")) {
        return "/private/dashboard/general";
      } else {
        return "/private/dashboard/fichas";
      }
    };

    return ((
      currentLocal ?
        (
          currentLocal?.systemIdentifier === SystemIdentifierEnum.Saas ?
            <Redirect to={`/private/saas/home`} />
            :
            <Redirect to={getPath()} />
        )
        :
        <div>Verificando Local</div>
    ))
  }

  const notFound = () => (
    <Layout>
      <h2>Página não encontrada</h2>
      <Link to={"/"}>Voltar para o inicio</Link>
    </Layout>
  )

  return (
    <Suspense fallback={<div style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress color="primary" /></div>}>
      <Switch>
        <Route exact path={"/"}>
          {
            <Redirect to={"/private/home"} />
          }
        </Route>
        <Route exact path={"/painel"} component={Painel} />
        <Route exact path={"/login"}>
          <Login />
        </Route>
        <Route exact path={"/signin"}>
          <Login />
        </Route>
        <Route exact path={"/public/guestList/:id"}>
          <PublicGuestList />
        </Route>
        <Route exact path={"/public/invoice/:localId"} component={PublicInvoicePage} />
        <Route exact path={"/login/esqueciSenha"} component={ForgotPassword} />
        <Route exact path={"/login/cadastro"} component={Signup} />
        <Route exact path="/public/lista-de-convidados/:id" component={PublicGuestList} />
        <Route exact path="/public/saas/">
          <Redirect to="/public/saas/register" />
        </Route>
        <Route path="/public/saas/">
          <Route exact path="/public/saas/register" component={SaasRegister} />
          <Route exact path="/public/saas/registerCielo" component={CieloSystemPage} />
          <Route exact path="/public/saas/login" component={LoginPage} />
          <Route exact path="/public/saas/simple-login" component={SimpleLoginPage} />
          <Route exact path="/public/saas/forget-password" component={ForgetPasswordPage} />
          <Route exact path="/public/saas/simple-forget-password" component={SimpleForgetPassword} />
          <Route exact path="/public/saas/change-password" component={ChangePasswordPage} />
          <Route exact path="/public/saas/code" component={CodePage} />
          <Route exact path="/public/saas/seller" component={CieloSellerPage} />
          <Route exact path="/public/saas/profile" component={ProfilePage} />
          <Route exact path="/public/saas/local" component={LocalPage} />
          <Route exact path="/public/saas/address" component={AddressPage} />
          <Route exact path="/public/saas/payment" component={PaymentPage} />

          <Route exact path="/public/saas/trial" component={TrialPage} />
          <Route exact path="/public/saas/fees" component={FeesPage} />
          <Route exact path="/public/saas/cieloPrices" component={CieloPosTable} />
          <Route exact path="/public/saas/track" component={SaasTrackPage} />
          <Route exact path="/public/saas/success-pos" component={SuccessPage} />
          <Route exact path="/public/saas/success" component={BasicSuccessPage} />
          <Route exact path="/public/saas/simple-success" component={SimpleSuccessPage} />
        </Route>
        <Route
          exact
          path="/public/lista-de-convidados/:id"
          component={PublicGuestList}
        />
        <AuthProvider>
          {/* <LocalProvider> */}
          <UserProvider>
            <MenuProvider>
              <PlansProvider>
                <BreadcumbsProvider homeUrl={"/"} placesUrl={"/private/"}>
                  <TipsProvider>
                    <Route exact path={"/private*"}>
                      <Switch>
                        <Route exact path="/private/" component={Local} />
                        <Route exact path="/private/home" component={Home} />

                        <Route
                          exact
                          path={"/private/tutoriais"}
                          component={Tutoriais}
                        />
                        <Route
                          exact
                          path={"/private/cadastrarTutorial"}
                          component={CadastrarTutorial}
                        />

                        <Route
                          exact
                          path={"/private/meepFood/:tab?/:subTab?"}
                          component={MeepFood}
                        />
                        <Route
                          exact
                          path={"/private/event/meepFood/:tab?/:eventId"}
                          component={MeepFood}
                        />

                        <Route
                          exact
                          path={"/private/gestaoVendas/"}
                          component={GestaoVendas}
                        />
                        <Route
                          exact
                          path={"/private/solucoesOnline/"}
                          component={DigitalMenu}
                        />
                        <Route
                          exact
                          path={"/private/configuracaoCardapioDigital/"}
                          component={DigitalMenuConfigPage}
                        />
                        <Route path="/private/dashboard">
                          {/* <Route exact path={"/private/dashboard/sales"} component={SalesDashboard} />
                                        <Route exact path={"/private/dashboard/vendas/:sessionId"} component={Dashboard} />
                                        <Route exact path={"/private/dashboard/aovivo"} component={Live} />
                                        <Route exact path={"/private/dashboard/customers/:eventId?"} component={CustomersDashboard} /> */}
                          <Route
                            exact
                            path={"/private/dashboard/:tab?/:id?"}
                            component={Dashboard}
                          />
                        </Route>
                        <Route
                          exact
                          path={"/private/event/dashboard/:tab?/:id?/:eventId"}
                          component={Dashboard}
                        />
                        <Route
                          path="/private/customers/categories"
                          exact
                          component={CustomerCategories}
                        />
                        <Route path="/private/customers/">
                          <Route
                            exact
                            path={"/private/customers/"}
                            component={Customers}
                          />
                        </Route>

                        <Route
                          exact
                          path={"/private/financeiro/link-pagamento"}
                          component={PaymentLink}
                        />

                        <Route
                          exact
                          path={"/private/financeiro/taxas"}
                          component={MeepFee}
                        />

                        <Route
                          exact
                          path={"/private/financial/permutation"}
                          component={Permutation}
                        />

                        <Route
                          exact
                          path={"/private/mapa-operacoes/pre-ativacao-cartoes"}
                          component={LinkCards}
                        />

                        <Route
                          exact
                          path={"/private/equipe/colaboradores/"}
                          component={Colaboradores}
                        />
                        <Route
                          exact
                          path={"/private/equipe/cargos/"}
                          component={Cargos}
                        />
                        <Route
                          exact
                          path={"/private/pdv/monitoramento/"}
                          component={Monitoramento}
                        />
                        <Route
                          exact
                          path={"/private/event/pdv/monitoramento/:eventId"}
                          component={Monitoramento}
                        />
                        <Route
                          exact
                          path={"/private/novidades/"}
                          component={Novidades}
                        />
                        <Route
                          exact
                          path={"/private/pdv/equipamento/:action?/"}
                          component={Device}
                        />
                        <Route
                          exact
                          path={
                            "/private/event/pdv/equipamento/:action?/:eventId"
                          }
                          component={Device}
                        />
                        <Route
                          exact
                          path={"/private/vendas/saleNow"}
                          component={SaasSaleWays}
                        />
                        <Route
                          exact
                          path={"/private/vendas/terminal/:action?/:id?/:step?/:deviceType?"}
                          component={SaasSaleWays}
                        />
                        <Route
                          exact
                          path={"/private/vendas/pos/:action?/:id?/:step?/:deviceType?"}
                          component={SaasSaleWays}
                        />
                        <Route
                          exact
                          path={
                            "/private/terminal"
                          }
                          component={Terminal}
                        />
                        <Route
                          exact
                          path={
                            "/private/terminal/SaleNow"
                          }
                          component={SaleNow}
                        />
                        <Route
                          exact
                          path={"/private/tickets/"}
                          component={MeepTickets}
                        />
                        <Route
                          exact
                          path={"/private/fidelity/:step?/:fidelityId?"}
                          component={FidelityPageContainer}
                        />

                        <Route
                          exact
                          path={"/private/saas/home"}
                          component={SaasHome}
                        />

                        <Route
                          exact
                          path={"/private/saas/mudar-plano"}
                          component={SaasChangePlan}
                        />

                        <Route
                          exact
                          path={"/private/saas/rastreamento/config"}
                          component={TrackingConfig}
                        />
                        <Route
                          exact
                          path={"/private/saas/payment"}
                          component={SaasPayment}
                        />
                        <Route
                          exact
                          path={"/private/saas/payment/success"}
                          component={SaasPaymentSuccess}
                        />
                        <Route
                          exact
                          path={"/private/saas/home"}
                          component={SaasHome}
                        />
                        <Route
                          exact
                          path={"/private/saas/mudar-forma-pagamento"}
                          component={ChangePaymentMethod}
                        />
                        <Route
                          exact
                          path={"/private/saas/rastreamento/config"}
                          component={TrackingConfig}
                        />
                        <Route
                          exact
                          path={"/private/saas/acesso-limitado"}
                          component={PlanLimitationWarning}
                        />


                        <Route path="/private/descontos/">
                          <Route
                            exact
                            path={"/private/descontos/"}
                            component={Discounts}
                          />

                          <Route
                            exact
                            path={"/private/descontos/adicionarLista"}
                            component={AddDiscountsPage}
                          />
                        </Route>
                        <Route path={"/private/eventos"}>
                          <Route
                            exact
                            path={"/private/eventos/list/:tab?"}
                            component={Events}
                          />
                          <Route
                            exact
                            path={"/private/eventos/adicionar/"}
                            component={AddEvents}
                          />
                        </Route>

                        <Route path={"/private/event/eventos"}>
                          <Route
                            exact
                            path={"/private/event/eventos/gerenciar/:eventId"}
                            component={Manage}
                          />
                          <Route
                            exact
                            path={"/private/event/eventos/fechamento/:eventId"}
                            component={CloseEvent}
                          />
                          <Route
                            exact
                            path={"/private/event/eventos/editar/:eventId"}
                            component={EditEvents}
                          />
                          <Route
                            exact
                            path={"/private/event/eventos/convidados/:eventId"}
                            component={EventPeopleList}
                          />
                          <Route
                            exact
                            path={"/private/event/eventos/listas/:eventId"}
                            component={EventGuestLists}
                          />
                          <Route
                            exact
                            path={
                              "/private/event/eventos/listas/adicionar/:eventId"
                            }
                            component={AddGuestList}
                          />
                          <Route
                            exact
                            path={
                              "/private/event/eventos/listas/editar/:eventListId"
                            }
                            component={AddGuestList}
                          />
                          <Route
                            exact
                            path={
                              "/private/event/eventos/listas/convidados/:guestListId"
                            }
                            component={GuestList}
                          />
                          <Route
                            exact
                            path={
                              "/private/event/eventos/listas/convidados/lista/adicionar/:listId"
                            }
                            component={AddGuest}
                          />
                        </Route>
                        <Route path={"/private/agendamento/"}>
                          <Route
                            exact
                            path={"/private/agendamento/servicos/"}
                            component={Services}
                          />
                          <Route
                            exact
                            path={"/private/agendamento/servicos/adicionar"}
                            component={AddServicesSchedule}
                          />
                          <Route
                            exact
                            path={
                              "/private/agendamento/servicos/editar/:serviceId"
                            }
                            component={EditServicesSchedule}
                          />
                          <Route
                            exact
                            path={"/private/agendamento/servicos/categorias/"}
                            component={ServiceCategories}
                          />
                          <Route
                            exact
                            path={"/private/agendamento/lugares/"}
                            component={PlaceSchedule}
                          />
                          <Route
                            exact
                            path={"/private/agendamento/lugares/adicionar"}
                            component={AddPlaceSchedule}
                          />
                          <Route
                            exact
                            path={"/private/agendamento/lugares/editar/:placeId"}
                            component={FormPlaceSchedule}
                          />
                          <Route
                            exact
                            path={"/private/agendamento/reservas"}
                            component={ReserveList}
                          />
                          <Route
                            exact
                            path={"/private/agendamento/reserva/adicionar"}
                            component={AddReserveSchedule}
                          />
                          <Route
                            exact
                            path={"/private/agendamento/colaborador/adicionar"}
                            component={AddColaborator}
                          />
                          <Route
                            exact
                            path={
                              "/private/agendamento/colaborador/editar/:collaboratorId"
                            }
                            component={EditColaborator}
                          />
                          <Route
                            exact
                            path={"/private/agendamento/colaborador/lista"}
                            component={Collaborators}
                          />
                          <Route
                            exact
                            path={"/private/agendamento/list/:tab?"}
                            component={Agendamento}
                          />
                          {/* <Route exact path={"/private/agendamento/:tab?"} component={Agendamento} /> */}
                        </Route>

                        <Route
                          exact
                          path={"/private/agendamento/lugares/customizarCampos"}
                        >
                          <CustomField />
                        </Route>

                        <Route
                          exact
                          path={"/private/event/fluxoCasa/:tab?/:eventId"}
                          component={HouseFlow}
                        />

                        <Route
                          exact
                          path={"/private/fluxoCasa/:tab?"}
                          component={HouseFlow}
                        />

                        <Route exact path={"/private/cuponsDesconto"}>
                          <DiscountCoupons />
                        </Route>

                        <Route path={"/private/products"}>
                          <Route
                            exact
                            path={"/private/products/:tab?/:subTab?"}
                            component={Products}
                          />
                          <Route
                            exact
                            path={"/private/products/edit/product/:productId"}
                            component={EditProduct}
                          />
                          <Route
                            exact
                            path={"/private/products/add/product/item"}
                            component={AdicionarProduto}
                          />
                        </Route>
                        <Route
                          exact
                          path={"/private/cadastros/:tab?/:subTab?"}
                          component={Cadastros}
                        />
                        <Route
                          exact
                          path={"/private/produtos/limitarAcesso"}
                          component={LimitarAcesso}
                        />
                        <Route
                          exact
                          path={
                            "/private/event/cadastros/:tab?/:subTab?/:eventId"
                          }
                          component={Cadastros}
                        />
                        <Route
                          exact
                          path={"/private/relatorios/:tab?/:subTab?"}
                          component={Relatorio}
                        />
                        <Route
                          exact
                          path={
                            "/private/event/relatorios/:tab?/:subTab?/:eventId"
                          }
                          component={Relatorio}
                        />
                        <Route
                          exact
                          path={"/private/cashless/relatorios/:tab?"}
                          component={Relatorio}
                        />
                        <Route
                          exact
                          path={"/private/faturas"}
                          component={Faturas}
                        />
                        <Route
                          exact
                          path={"/private/estoque/:tab?/:subTab?"}
                          component={Estoque}
                        />
                        <Route
                          exact
                          path={"/private/servicos/:tab?/:subTab?"}
                          component={Servicos}
                        />

                        <Route
                          exact
                          path={"/private/doacoes/:tab?/:subTab?"}
                          component={Doacoes}
                        />
                        <Route
                          exact
                          path={"/private/contaDigital/:type?"}
                          component={ContaDigital}
                        />
                        <Route
                          exact
                          path={"/private/contaDigital/:type?/extrato/:account?"}
                          component={Extrato}
                        />
                        <Route
                          exact
                          path={"/private/contaDigital/:type?/antecipate-received/:account?"}
                          component={AntecipateReceived}
                        />
                        <Route
                          exact
                          path={"/private/donate"}
                          component={Donations}
                        />
                        <Route
                          exact
                          path={"/private/contaDigital/:type?/antecipate/:account?"}
                          component={Antecipate}
                        />
                        <Route
                          exact
                          path={"/private/cartoes/:tab?"}
                          component={Cartoes}
                        />
                        <Route
                          exact
                          path={"/private/event/cartoes/:tab?/:eventId"}
                          component={Cartoes}
                        />
                        <Route
                          exact
                          path={
                            "/private/configuracao/:tab?/:subTab?/:action?/:id?/:step?/:deviceType?"
                          }
                          component={Configuracao}
                        />
                        <Route
                          exact
                          path={
                            "/private/configuracoes/:tab?/:subTab?/:action?/:id?/:step?/:deviceType?"
                          }
                          component={Configuracao}
                        />

                        <Route
                          exact
                          path={
                            "/private/pdv/perfil/:action?/:id?/:step?/:deviceType?"
                          }
                          component={Perfil}
                        />
                        <Route
                          exact
                          path={
                            "/private/pdv/perfil/:action?/:id?/:step?/:deviceType?"
                          }
                          component={Perfil}
                        />
                        <Route
                          exact
                          path={"/private/pdv/evento/perfil"}
                          component={PerfilEventos}
                        />

                        <Route exact path={"/private/pdv/impressoras-inteligentes"}>
                          <SmartPrinter />
                        </Route>

                        <Route
                          exact
                          path={"/private/event/pdv/perfil/:eventId"}
                          component={PerfilEventos}
                        />

                        <Route
                          exact
                          path={"/private/admin/:tab?/:subTab?"}
                          component={Admin}
                        />
                        <Route
                          exact
                          path={"/private/admin-novidades"}
                          component={AdminNovidades}
                        />

                        <Route
                          exact
                          path={"/private/configuracoes/:tab?/:subTab?"}
                          component={Admin}
                        />

                        <Route
                          exact
                          path={"/private/automatizacao/:tab?"}
                          component={Automations}
                        />

                        <Route
                          exact
                          path={"/private/automation"}
                          component={AutomationPage}
                        />

                        <Route
                          exact
                          path={"/private/invoice"}
                          component={InvoicePage}
                        />

                        <Route
                          exact
                          path={"/private/invoice/form"}
                          component={InvoiceForm}
                        />

                        <Route
                          exact
                          path={"/private/invoice/tax/create"}
                          component={TaxForm}
                        />

                        <Route
                          exact
                          path={"/private/invoice/tax/edit/:id"}
                          component={TaxForm}
                        />

                        <Route
                          exact
                          path={"/private/orders"}
                          component={Orders}
                        />

                        <Route path={"/private/catalog"}>
                          <Route
                            exact
                            path={"/private/catalog/:catalogId?"}
                            component={Catalog}
                          />
                          {/* <Route
                                exact
                                path={"/private/catalog/:catalogId/product/:action/:id"}
                                component={AddProduct}
                              /> */}
                          <Route
                            exact
                            path={"/private/catalog/product/:action"}
                            component={AddProduct}
                          />
                        </Route>
                        {/* <Route exact path={"/private/plan"} component={PlanPage} /> */}
                        <Route
                          exact
                          path={"/private/transacoes/:tab?/:subTab?"}
                          component={Transacoes}
                        />
                        <Route
                          exact
                          path={"/private/integrations/:tab?/:subTab?"}
                          component={Integracao}
                        />
                        <Route
                          exact
                          path={"/private/posPago/:tab?/:subTab?"}
                          component={PosPago}
                        />
                        <Route
                          exact
                          path={"/private/event/cashless/:tab?/:subTab?/:eventId"}
                          component={Cashless}
                        />
                        <Route
                          exact
                          path={"/private/cashless/:tab?/:subTab?"}
                          component={Cashless}
                        />

                        <Route
                          exact
                          path={"/private/crm/:tab?"}
                          component={Crm}
                        />
                        <Route
                          exact
                          path={"/private/crm/campaign/edit/:campaignId"}
                          component={CrmCampaignEdit}
                        />
                        <Route
                          exact
                          path={"/private/crm/campaign/report/:campaignId"}
                          component={Report}
                        />
                        <Route
                          exact
                          path={"/private/crm/campaign/config"}
                          component={ConfigCampanha}
                        />

                        <Route
                          exact
                          path={"/private/survey"}
                          component={SurveyLandingPage}
                        />

                        <Route
                          exact
                          path={"/private/track"}
                          component={TrackDetail}
                        />

                        <Route
                          exact
                          path={"/private/track/:action?/:id?"}
                          component={TrackPage}
                        />

                        <Route
                          exact
                          path={"/private/omie/detail"}
                          component={OmieDetail}
                        />
                        <Route
                          exact
                          path={"/private/sankhya/detail"}
                          component={SankhyaDetail}
                        />
                        <Route
                          exact
                          path={"/private/everest/detail"}
                          component={EverestDetail}
                        />

                        <Route exact path={"/private/plans"} component={Plans} />
                        <Route
                          exact
                          path={"/private/plans/purchase/:id"}
                          component={PurchasePlan}
                        />
                        <Route
                          exact
                          path={"/private/plans/history"}
                          component={PlanPaymentHistory}
                        />

                        <Route
                          exact
                          path={"/private/plans/config"}
                          component={ConfigPlans}
                        />

                        <Route
                          exact
                          path={"/private/onlinePayment/config"}
                          component={ConfigOnlinePayment}
                        />

                        <Route
                          exact
                          path={"/private/push/purchase"}
                          component={PurchasePush}
                        />
                        <Route
                          exact
                          path={"/private/email/purchase"}
                          component={PurchaseEmail}
                        />

                        <Route
                          exact
                          path={"/private/erp"}
                          component={ERPLandingPage}
                        />

                        <Route
                          exact
                          path={"/private/bi/configure"}
                          component={ConfigBI}
                        />
                        <Route
                          exact
                          path={"/private/bi/purchase"}
                          component={PurchaseBi}
                        />
                        <Route
                          exact
                          path={"/private/bi/:id?"}
                          component={BIPageContainer}
                        />

                        <Route
                          exact
                          path={"/private/ferramentas"}
                          component={ToolsPage}
                        />

                        <Route
                          exact
                          path={"/private/ferramentas/calculadora-ifood"}
                          component={IfoodCalculatorPage}
                        />

                        <Route
                          exact
                          path={"/private/ferramentas/calculadora-bobina"}
                          component={CoilCalculatorPage}
                        />

                        <Route
                          exact
                          path={"/private/onboarding"}
                          component={Onboarding}
                        />

                        <Route path={"/private/catalog"}>
                          <Route
                            exact
                            path={"/private/catalog/:catalogId?"}
                            component={Catalog}
                          />
                          {/* <Route
                              exact
                              path={"/private/catalog/:catalogId/product/:action/:id"}
                              component={AddProduct}
                            /> */}
                          <Route
                            exact
                            path={"/private/catalog/product/:action"}
                            component={AddProduct}
                          />
                        </Route>
                        <Route path="/private/meeperp" component={ErpRoutes} />
                        <Route
                          exact
                          path={"/private/external"}
                          component={External}
                        />
                        <Route
                          exact
                          path={"/private/access-denied"}
                          component={AccessDeniedPage}
                        />
                        <Route
                          exact
                          path={"/private/posts"}
                          component={Posts}
                        />
                        <Route
                          exact
                          path={"/private/main"}
                          component={MainDashboard}
                        />
                        <Route
                          exact
                          path={"*"}
                          component={notFound}
                        />
                      </Switch>
                    </Route>
                  </TipsProvider>
                </BreadcumbsProvider>
              </PlansProvider>
            </MenuProvider>
          </UserProvider>
          {/* </LocalProvider> */}
        </AuthProvider>
      </Switch>
    </Suspense>
  );
};
export default Routes;